import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/anKangBIOne'
  },
  {
    name: 'overallSituation',
    component: () => import('./view/overallSituation/overallSituation.vue'),
    meta: {
      title: '安康数据大屏（培训情况）'
    }
  },
  {
    name: 'trainingSituation',
    component: () => import('./view/trainingSituation/trainingSituation.vue'),
    meta: {
      title: '培训情况统计分析'
    }
  },
  {
    name: 'trainingSituationOrg',
    component: () => import('./view/trainingSituationOrg/trainingSituationOrg.vue'),
    meta: {
      title: '机构培训情况统计分析'
    }
  },
  {
    name: 'employmentBig',
    component: () => import('./view/employmentStatus/employmentBig.vue'),
    meta: {
      title: '就业情况统计分析'
    }
  },
  {
    name: 'orgEmployment',
    component: () => import('./view/orgEmployment/orgEmployment.vue'),
    meta: {
      title: '机构就业情况'
    }
  },
  {
    name: 'anKangBIOne',
    component: () => import('./view/anKangBIOne/anKangBIOne.vue'),
    meta: {
      title: '安康数据大屏（基本信息）'
    }
  },
  {
    name: 'trainingSituation2',
    component: () => import('./view/trainingSituation2/trainingSituation.vue'),
    meta: {
      title: '安康数据大屏（实时信息）'
    }
  },
  {
    name: 'trainingSituation3',
    component: () => import('./view/trainingSituation2/trainingSituation3.vue'),
    meta: {
      title: '安康数据大屏（规划信息）'
    }
  },
  {
    name: 'zhihuijiankongdaping3',
    component: () => import('./view/zhihuijiankongdaping3/zhihuijiankongdaping3.vue'),
    meta: {
      title: '安康数据大屏（四）'
    }
  },
  {
    name: 'dataMaintenance',
    component: () => import('./view/dataMaintenance/dataMaintenance.vue'),
    meta: {
      title: '大屏数据维护(一)'
    }
  },
  {
    name: 'dataMaintenance1',
    component: () => import('./view/dataMaintenance/dataMaintenance1.vue'),
    meta: {
      title: '大屏数据维护(二)'
    }
  },
  {
    name: 'dataMaintenance2',
    component: () => import('./view/dataMaintenance/dataMaintenance2.vue'),
    meta: {
      title: '大屏数据维护(三)'
    }
  },
  {
    name: 'dataMaintenance3',
    component: () => import('./view/dataMaintenance/dataMaintenance3.vue'),
    meta: {
      title: '大屏数据维护(四)'
    }
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({routes});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
