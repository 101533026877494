<template>
  <div style="margin:0;height: 100vh">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: "index2",
  }
</script>

<style scoped>
</style>
