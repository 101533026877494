import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import { router } from './router';
import ViewUI from 'view-design';
import BaiduMap from 'v-baidu-map'
import echarts from 'echarts'
import context from "./util/context";
import 'view-design/dist/styles/iview.css';
const $my = {
  baseUrl: context.baseUrl,

};
Vue.use(VueRouter);
Vue.use(ViewUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$my = $my;
Vue.use(BaiduMap,{
  //ak是在百度地图开发者平台申请的密钥
  ak:'hn9v7751MrjtOmOZisQSF5ErYzNbFU6b'
});
//ak:'vn08UTISzaGatC8n32z61eW4B3eUL9AT' 旧项目用的ak
//hn9v7751MrjtOmOZisQSF5ErYzNbFU6b 新ak
new Vue({
  router,
  el: '#app',
  render:h=>h(App)
});
