<template>
  <Index v-if="target === '_self'"/>
  <Index2 v-else-if="target==='_blank'"/>
</template>

<script>
  import Index from "./view/index.vue"
  import Index2 from "./view/index2.vue"
  import querystring from 'querystring';

  export default {
    name: 'App',
    components: {
      Index, Index2
    },
    data() {
      return {
        target: '_self'
      };
    },
    created() {
      let url = location.href;
      let str = url.split("?")[1];
      let obj = querystring.parse(str);
      this.target = obj.target || '_self';
    }
  }
</script>

<style>
</style>
