<template>
  <Layout ref="main" style="transform:scale(1)">
    <!--首页头部-->
<!--    <Header style="display: flex;justify-content: space-between">-->
<!--      &lt;!&ndash;左部，显示系统名称&ndash;&gt;-->
<!--      <div class="header-left">-->
<!--        <span style="font-size: 24px;color: white;">大屏导航</span>-->
<!--      </div>-->
<!--      <div class="header-right">-->
<!--        <Button size="small" type="text" style="color:#FF00FF;" @click="openInBlank">-->
<!--          在新页签打开本功能-->
<!--        </Button>-->
<!--      </div>-->
<!--    </Header>-->
    <Layout>
      <!--导航菜单-->
      <Drawer title="大屏导航" placement="left" :closable="false" v-model="isDrawered" :styles="DrawerStyles" class-name="theDrawer" :mask-style="theMaskStyle">
        <Menu active-name="1-6" theme="dark" width="auto" class="theMenu">
          <MenuItem name="1-6" key="1-6" to="/anKangBIOne">
            <span>安康数据大屏（基本信息）</span>
          </MenuItem>
          <MenuItem name="1-7" key="1-7" to="/trainingSituation2">
            <span>安康数据大屏（实时信息）</span>
          </MenuItem>
          <MenuItem name="1-8" key="1-8" to="/trainingSituation3">
            <span>安康数据大屏（规划信息）</span>
          </MenuItem>
          <MenuItem name="1-1" key="1-1" to="/overallSituation">
            <span>安康数据大屏（培训情况）</span>
          </MenuItem>
<!--            <MenuItem name="1-2" key="1-2" to="/dataMaintenance">-->
<!--            <span>大屏数据维护(一)</span>-->
<!--          </MenuItem>-->
<!--            <MenuItem name="1-3" key="1-3" to="/dataMaintenance1">-->
<!--            <span>大屏数据维护(二)</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-4" key="1-4" to="/dataMaintenance2">-->
<!--            <span>大屏数据维护(三)</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-5" key="1-5" to="/dataMaintenance3">-->
<!--            <span>大屏数据维护(四)</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-2" key="1-2" to="/trainingSituation">-->
<!--            <span>培训情况分析</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-4" key="1-4" to="/employmentBig">-->
<!--            <span>就业情况分析</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-3" key="1-3" to="/trainingSituationOrg">-->
<!--            <span>机构培训情况分析</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-5" key="1-5" to="/orgEmployment">-->
<!--            <span>机构就业情况</span>-->
<!--          </MenuItem>-->
          <!--          <MenuItem name="1-6" key="1-6" to="/theThirdPage">-->
          <!--            <span>大屏3</span>-->
          <!--          </MenuItem>-->

<!--          <MenuItem name="1-9" key="1-9" to="/zhihuijiankongdaping3">-->
<!--            <span>安康数据大屏（四）</span>-->
<!--          </MenuItem>-->
        </Menu>
      </Drawer>
<!--      <Sider hide-trigger width="210" collapsible :collapsed-width="0"  v-model="isCollapsed">-->
<!--        <Menu active-name="1-1" theme="dark" width="auto">-->
<!--          <MenuItem name="1-1" key="1-1" to="/overallSituation">-->
<!--            <span>总体情况分析</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-2" key="1-2" to="/trainingSituation">-->
<!--            <span>培训情况分析</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-4" key="1-4" to="/employmentBig">-->
<!--            <span>就业情况分析</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-3" key="1-3" to="/trainingSituationOrg">-->
<!--            <span>机构培训情况分析</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-5" key="1-5" to="/orgEmployment">-->
<!--            <span>机构就业情况</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-6" key="1-6" to="/anKangBIOne">-->
<!--            <span>安康数据大屏（一）</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-7" key="1-7" to="/trainingSituation2">-->
<!--            <span>安康数据大屏（二）</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-8" key="1-8" to="/trainingSituation3">-->
<!--            <span>安康数据大屏（三）</span>-->
<!--          </MenuItem>-->
<!--          <MenuItem name="1-9" key="1-9" to="/zhihuijiankongdaping3">-->
<!--            <span>安康数据大屏（四）</span>-->
<!--          </MenuItem>-->
<!--        </Menu>-->
<!--      </Sider>-->
      <!--内容-->
      <Content style="width: 100%;height:calc(100vh);position: relative;">
<!--          <div class="layout-content-main" style="border-radius: 8px;">-->
            <router-view/>
<!--        </div>-->
      </Content>
    </Layout>
    <div style="color: rgb(255, 255, 255);z-index:999;
    position: fixed;
    left: 0px;
    top: 0px;
    " @click="changeCollapsed">
      <Icon type="md-arrow-dropright" color="#005ffc" size="30" v-show="!isDrawered"/>
    </div>
    <div style="color: rgb(255, 255, 255);z-index:999;
    position: fixed;
    right: 5px;
    top: 5px;
    " @click="changeFullScreen">
      <Icon type="md-expand" size="20" v-show="!isFullScreen"/>
      <Icon type="md-contract" size="20" v-show="isFullScreen"/>
    </div>
  </Layout>
</template>

<script>
    //按需引入组件
    export default {
        name: "index",
        components: {
        },
        data() {
            return {
              client_:'',
              isCollapsed: false,
              isDrawered: false,
              isFullScreen: false,
              DrawerStyles: {
                padding: 0,
                backgroundColor: 'rgba(0,0,0,.1)'
              },
              theMaskStyle: {
                backgroundColor: 'rgba(55,55,55,.3)'
              }
            }
        },
        methods: {
          changeFullScreen() {
            if(!this.isFullScreen) {
              if (document.body.requestFullscreen) {
                document.body.requestFullscreen()
              } else if (document.body.mozRequestFullScreen) {
                document.body.mozRequestFullScreen()
              } else if (document.body.webkitRequestFullScreen) {
                document.body.webkitRequestFullScreen()
              } else if (document.body.msRequestFullscreen) {
                document.body.msRequestFullscreen()
              }
              this.isFullScreen = !this.isFullScreen
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen()
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
              } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
              }
              this.isFullScreen = !this.isFullScreen
            }
          },
          changeCollapsed() {
            // this.isCollapsed = !this.isCollapsed;
            this.isDrawered = !this.isDrawered;
          },
          openInBlank() {
            let url = location.href;
            if (url.indexOf('?') === -1) {
              url += '?target=_blank';
            } else {
              url += '&target=_blank';
            }
            window.open(url, '_blank');
          },
        },
      mounted() {
      },
    }
</script>

<style scoped >
  .notice-icon {
    margin-right: 20px;
    margin-left: 20px;
    font-size: x-large;
    padding-right: 20px;
    border-right: 1px solid #fff;
  }
  .util-wrap {
    display: flex;
    align-items: center;
  }
  .headerDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    color: white;
    font-size: x-large;
  }
  .content {
    height: calc(100vh - 66px);
  }

  /* 左边导航栏字体颜色 2021-04-01增加 */
  .ivu-menu {
    color: #dcdee2 !important;
  }

  .content-wrap {
    height: calc(100vh - 2px);
  }

  .layout {
    border: 1px solid #d7dde4;
    background: #2a378f !important;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }

  .menu-icon {
    transition: all 0.3s;
  }

  .rotate-icon {
    transform: rotate(-90deg);
  }

  .menu-item span {
    /*display: inline-block;*/
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width 0.2s ease 0.2s;
    margin-left: 12px;
  }

  .menu-item i {
    transform: translateX(0px);
    transition: font-size 0.2s ease, transform 0.2s ease;
    vertical-align: middle;
    font-size: 16px;
  }

  .collapsed-menu span {
    width: 0px;
    transition: width 0.2s ease;
  }

  .collapsed-menu i {
    transform: translateX(15px);
    transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
    vertical-align: middle;
    font-size: 22px;
  }

  /*刘锟 20210331*/
  .ivu-menu-vertical .ivu-menu-item,
  .ivu-menu-vertical .ivu-menu-submenu-title {
    padding: 14px 24px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    transition: all 0.2s ease-in-out;
  }




</style>
<style>
.theDrawer .ivu-drawer-content{
  background-color: rgba(0,0,0,.75);
}
.theDrawer .ivu-drawer-header-inner{
  color: #FFFFFF;
}
.theDrawer .ivu-drawer-header {
  border-bottom: 0;
  background-color: rgba(17, 61, 135, .3);
}
.theMenu {
  background: rgba(0,0,0,0)!important;
}
</style>
